// import AOS from 'aos';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';
import "magnific-popup";


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  //scroll top
  $('#back_to_top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 0);
  });

  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
      $('#back_to_top').addClass("is-active");
    } else {
      $('.header').removeClass("min-header");
      $('#back_to_top').removeClass("is-active");
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).addClass('active');
    $('.header-menu-outer').addClass('active');
    $('.float-btn-body').addClass('hide');
  });

  $('.header-menu-close').on('click', function () {
    $(this).removeClass('active');
    $('.header-menu-outer').removeClass('active');
    $('.float-btn-body').removeClass('hide');
  });

  $('#float_btns_opt').on('click', function () {
    $('#float_btns').toggleClass('is-active');
  });

  $('.menu-link').click(function(e) {
    $(this).parent().toggleClass('active').siblings().removeClass('active');
  });


  // dropdown
  $('.dropdown-btn').on('click', function () {
    const id = $(this).attr('id');
    const dropdown_is_active = $('[aria-labelledby=' + id + ']').hasClass("is-active");
    if (dropdown_is_active >= 1) {
      $('[aria-labelledby=' + id + ']').removeClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '',
          'overflow': '',
        })
      }
    } else {
      $(".dropdown-list:not([aria-labelledby=" + id + "]").removeClass('is-active');
      $('[aria-labelledby=' + id + ']').addClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '100vh',
          'overflow': 'hidden',
        })
      }
    }
  });

  function treatment_cate() {
    var treatment_cate_id = $('[name=treatments_cate]:checked').attr('id');
    $("[aria-labelledby=treatments_cate]").find('.items-cate[data-cate=' + treatment_cate_id + ']').addClass('is-active').siblings().removeClass('is-active');
  }
  treatment_cate();

  $('.group-opts').find('.dropdown-list-item').on('click', function() {
    treatment_cate();
  });

  $(document).click(function (event) {
    if (!$(event.target).closest('.dropdown-btn').length) {
      if (!$(event.target).closest('.group-opts').length) {
        if ($('.dropdown-list').is(":visible")) {
          $(".dropdown-list").removeClass('is-active');
          if (window.innerWidth <= 640) {
            $('body').css({
              'height': '',
              'overflow': '',
            })
          }
        }
      }
    }
    if (!$(event.target).closest('#float_btns_opt').length) {
      if ($('#float_btns').is(":visible")) {
        $("#float_btns").removeClass('is-active');
      }
    }
  });

  // tab
  $('.tab[data-tabs]').on('click', function () {
    var tabPanes = $(this).data('tabs');
    var ID = $(this).data('tab-target');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings().removeClass('is-active');
  });

  var index_articles = new Swiper('#index_articles', {
    slidesPerView: 1.4,
    spaceBetween: 0,
    centeredSlides: true,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 25,
    breakpoints: {
      680: {
        centeredSlides: false,
        slidesPerView: 2,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
      913: {
        centeredSlides: false,
        slidesPerView: 3,
      }
    },
    navigation: {
      nextEl: '.index-article-next',
      prevEl: '.index-article-prev',
    },
  });

  var treatment_showcases = new Swiper('#treatment_showcases', {
    slidesPerView: 1,
    spaceBetween: 20,
    autoplay: {
      delay: 3000
    },
    breakpoints: {
      480: {
        slidesPerView: 2,
        autoplay: false,
        spaceBetween: 20,
      },
      913: {
        slidesPerView: 1,
        spaceBetween: 23,
      }
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
  });

  // showcase before after
  var showcase_before = new Swiper('#showcase_before', {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-bf-btn-next',
      prevEl: '.swiper-bf-btn-prev',
    },
  });

  var showcase_after = new Swiper('#showcase_after', {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-ba-btn-next',
      prevEl: '.swiper-ba-btn-prev',
    },
  });

  // video
  var video_swiper = new Swiper('#video_swiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    watchSlidesProgress: true,
    navigation: {
      nextEl: ".video-next",
      prevEl: ".video-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 2,
      },
      750: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 22,
      },
    },
  });

  $('.video-youtube').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade video-iframe',
    removalDelay: 160,
  });

  $('.video-youtube-short').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade video-short-iframe',
    removalDelay: 160,
  });

  //doctors
  $('.doctor-certification-btn').on('click', function (e) {
    e.preventDefault();
    var gallery = $(this).attr('href');
    $(gallery).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true
      }
    }).magnificPopup('open');
  });

});

